import { corpDomains, fileType } from './domainsAndTypes.js'

export function notCorpEmail(value) {
    const lowerValue = value.toLowerCase()
    const domain = lowerValue.split('@')

    return domain[1] ? corpDomains.indexOf(domain[1]) === -1 : false
}

// eslint-disable-next-line no-unused-vars
export function usrFileType(value, input) {
    // eslint-disable-next-line no-undef
    const inp = $('#usr_file')
    const fileName = inp[0].files[0].name
    const lowerValue = fileName.toLowerCase()
    const type = lowerValue.split('.')

    return type[1] ? fileType.indexOf(type[1]) !== -1 : false
}

export function maxFileSize(value, input, size) {
    // eslint-disable-next-line no-undef
    const inp = $('#usr_file')
    const fileSize = inp[0].files[0].size

    return fileSize <= size
}

export function minDigitsLength(value, input, count) {
    const digitCount = value.replace(/\D+/g, '').length

    return digitCount >= count
}

export function maxDigitsLength(value, input, count) {
    const digitCount = value.replace(/\D+/g, '').length

    return digitCount <= count
}

export function isEmail(value) {
    // eslint-disable-next-line max-len, no-useless-escape
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return regex.test(value)
}

// check only numbers
export function isNumber(value) {
    const regex = /^\d+$/

    return regex.test(value)
}
