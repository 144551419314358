// formHandler.js
import {
    isEmail,
    minDigitsLength,
    maxDigitsLength,
    isNumber,
} from './../utils/customValidators'
import {
    SUCCESS_MESSAGE,
    ERROR_MESSAGE,
    ERROR_FIELD,
    ERROR_MAIL,
    PHONE_LONG,
    PHONE_SHORT,
    ERROR_NUMBERS_ONLY,
} from './../utils/messages'

// eslint-disable-next-line no-undef
const FORM_URL = data.ajax_url

function highlight(element, msg = ERROR_FIELD) {
    unhighlight(element)
    element.classList.add('error')

    // append error message
    const errorElement = document.createElement('small')
    errorElement.classList.add('error-message')

    errorElement.textContent = msg
    element.parentNode.appendChild(errorElement)
}

function unhighlight(element) {
    element.classList.remove('error')
    // remove error message if exists
    const errorElement = element.parentNode.querySelector('.error-message')
    if (errorElement) {
        errorElement.remove()
    }
}

function getErrorForInput(input) {
    // For email validation
    if (input.name === 'email') {
        if (!isEmail(input.value)) {
            return ERROR_MAIL
        }
    }

    // For phone validation
    if (input.name === 'phone') {
        if (!isNumber(input.value)) {
            return ERROR_NUMBERS_ONLY
        }

        if (!minDigitsLength(input.value, input, 6)) {
            return PHONE_SHORT
        }
        if (!maxDigitsLength(input.value, input, 11)) {
            return PHONE_LONG
        }
    }

    return null
}

function isValidForm(form) {
    let isValid = true

    const inputs = form.querySelectorAll('input')

    inputs.forEach((input) => {
        const errorMsg = getErrorForInput(input)
        if (errorMsg) {
            isValid = false
            highlight(input, errorMsg)
        } else {
            unhighlight(input)
        }
    })

    return isValid
}

function isValidInput(input) {
    const errorMsg = getErrorForInput(input)
    if (errorMsg) {
        highlight(input, errorMsg)

        return false
    }
    unhighlight(input)

    return true
}

export function initFormValidation() {
    const forms = document.querySelectorAll('form.main-form')

    const num1 = Math.floor(Math.random() * 10) + 1
    const num2 = Math.floor(Math.random() * 10) + 1

    const encodedNum1 = btoa(num1.toString())
    const encodedNum2 = btoa(num2.toString())

    // Add the encoded numbers as hidden inputs
    const input1 = document.createElement('input')
    input1.type = 'hidden'
    input1.name = 'encoded_num1'
    input1.value = encodedNum1

    const input2 = document.createElement('input')
    input2.type = 'hidden'
    input2.name = 'encoded_num2'
    input2.value = encodedNum2

    forms.forEach((form) => {
        form.querySelector(
            '.check-text',
        ).textContent = `Антиспам: ${num1} + ${num2} = ?`
        form.appendChild(input1)
        form.appendChild(input2)

        // add form reset
        // eslint-disable-next-line no-unused-vars
        form.addEventListener('reset', (event) => {
            // clear form fields
            form.querySelectorAll('input').forEach((input) => {
                input.value = ''
            })
        })

        // eslint-disable-next-line no-unused-vars
        form.addEventListener('ok', (event) => {
            form.querySelectorAll('.form-row').forEach((el) => {
                if (!el.classList.contains('form-row__output')) {
                    // hide element
                    el.style.display = 'none'
                }
            })
        })

        form.addEventListener('submit', (event) => {
            event.preventDefault()

            if (isValidForm(form)) {
                const formData = new FormData(form)

                // collect all utm params from current url
                const utmParams = new URLSearchParams(window.location.search)
                utmParams.forEach((value, key) => {
                    // append param it its key has 'utm' prefix
                    if (key.startsWith('utm')) {
                        formData.append(key, value)
                    }
                })

                fetch(FORM_URL, {
                    method: 'POST',
                    body: formData,
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok')
                        }

                        return response.json()
                    })
                    .then((result) => {
                        const outputMessage =
                            form.querySelector('.form-row__output')
                        if (result.success) {
                            outputMessage.classList.add('success')
                            outputMessage.textContent = SUCCESS_MESSAGE
                            form.reset()
                            form.ok()
                        } else {
                            outputMessage.classList.add('error')
                            outputMessage.textContent = ERROR_MESSAGE
                        }
                    })
            } else {
                const outputMessage = form.querySelector('.form-row__output')
                outputMessage.classList.add('error')
                outputMessage.textContent = ERROR_MESSAGE
            }
        })

        form.addEventListener('input', (event) => {
            if (isValidInput(event.target)) {
                unhighlight(event.target)
            }
        })
    })
}

// scroll to form with any button with class form_scroll_btn or href="#form"
document.addEventListener('click', function(e) {
    const mainForm = document.querySelector('.main-form')

    if (
        e.target.classList.contains('form_scroll_btn') ||
        e.target.href.indexOf('#form') > -1
    ) {
        e.preventDefault()
        mainForm.scrollIntoView({ behavior: 'smooth' })
    }
})
